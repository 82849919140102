<template>
    <div class="mt-5">
        <h3>{{ $t("overviews.collectedRevenueOver") }}</h3>
        <div class="row align-items-center mb-2 mt-4">
            <div class="col-2">
                <button @click="setWarehouseLastWeek" class="btn button-top">{{ $t("overviews.lastWeek") }}</button>
            </div>
            <div class="col-2">
                <button @click="setWarehouseLastMonth" class="btn button-top">{{ $t("overviews.lastMonth") }}</button>
            </div>
            <div class="col-2">
                <button @click="setWarehouseLastYear" class="btn button-top">{{ $t("overviews.lastYear") }}</button>
            </div>
            <div class="col-2">
                <button @click="setWarehouseActualMonth" class="btn button-top">{{ $t("overviews.actualMonth")
                    }}</button>
            </div>
        </div>
        <div class="row mb-3 align-items-center">
            <div class="col-2">
                <label class="control-label">{{ $t("overviews.from") }}</label>
                <input type="date" v-model="fromWarehouses" :max="toWarehouses" class="form-control" />
            </div>
            <div class="col-2">
                <label class="control-label">{{ $t("overviews.to") }}</label>
                <input type="date" v-model="toWarehouses" :min="fromWarehouses" class="form-control" />
            </div>
            <div class="col-2">
                <label class="control-label">{{ $t("overviews.collectedBy") }}</label>
                <select v-model="collected_by" class="form-control form-select">
                    <option :value="null">{{ $t("overviews.everyone") }}</option>
                    <option v-for="owner in owners" :value="owner.id">{{ owner.name }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div id="CZK_revenue_warehouses_chart_div" class="col-12 mb-2" style="width: 100%; height: 450px;"></div>
            <div id="EUR_revenue_warehouses_chart_div" class="col-12 mb-2" style="width: 100%; height: 450px;"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        warehouses: {
            type: Array,
            required: false,
            default: []
        },
    },
    data() {
        return {
            toWarehouses: null,
            fromWarehouses: null,
            collected_by: null,
            warehouses: [],
            owners: [],
        }
    },
    watch: {
        toWarehouses: function () {
            this.getWarehousesStats()
        },
        fromWarehouses: function () {
            this.getWarehousesStats()
        },
        warehouses_done: function () {
            this.getWarehousesStats()
        },
        collected_by: function () {
            this.getWarehousesStats()
        }
    },
    created: function () {
        this.toWarehouses = this.getDateFormatted(new Date());
        var from = new Date();
        from.setMonth(from.getMonth() - 1);
        this.fromWarehouses = this.getDateFormatted(from);
        this.getWarehousesStats();
        this.getOwners();
    },
    methods: {
        getOwners: function () {
            this.$store.getters.api.get('/users').then(function (response) {
                var users = response.data.items;
                for (var i = 0; i < users.length; i++) {
                    for (var j = 0; j < users[i].functions.length; j++) {
                        if (users[i].functions[j].name == "owner") {
                            this.owners.push(users[i]);
                        }
                    }
                }
            }.bind(this));
        },
        getDateFormatted: function (date) {
            return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
        },
        getWarehousesStats: function () {
            this.$store.getters.api.get('/get_collected_revenue_by_warehouses', { params: { to: this.toWarehouses, from: this.fromWarehouses, collected_by: this.collected_by } }).then(function (response) {
                this.warehouses = response.data.data;
                this.draw(this.drawWarehousesChartCZK);
                this.draw(this.drawWarehousesChartEUR);
            }.bind(this));
        },
        draw: function (fn, language) {
            google.charts.load("current", { packages: ["corechart"], 'language': "cs" });
            google.charts.setOnLoadCallback(fn);
        },
        drawWarehousesChartCZK: function () {
            this.drawWarehousesChart("CZK");
        },
        drawWarehousesChartEUR: function () {
            this.drawWarehousesChart("EUR");
        },
        drawWarehousesChart: function (currency) {
            var array = [["Sklad", "Celkem", { role: 'style' }]]
            for (var i = 0; i < this.warehouses.length; i++) {
                array.push([this.warehouses[i].name, this.warehouses[i].revenue[currency].total, this.warehouses[i].color])
            }
            var data = google.visualization.arrayToDataTable(array);

            var options = {
                title: 'Celkem převzatých tržeb za dané sklady v ' + currency,
                backgroundColor: {
                    fill: '#FFFFFF',
                    fillOpacity: 0.5
                },
                legend: { position: "none" },
                titleTextStyle: {
                    fontSize: 18
                }
            };

            var chart = new google.visualization.ColumnChart(document.getElementById(currency + '_revenue_warehouses_chart_div'));
            chart.draw(data, options);
        },

        setWarehouseLastWeek: function () {
            var to = new Date()
            to.setDate(to.getDate() - to.getDay());
            this.toWarehouses = this.getDateFormatted(to);
            var from = new Date();
            from.setDate(from.getDate() - 6 - from.getDay());
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        setWarehouseLastMonth: function () {
            var to = new Date();
            to.setMonth(to.getMonth());
            to.setDate(0);
            var from = new Date();
            from.setMonth(from.getMonth() - 1);
            from.setDate(1);
            this.toWarehouses = this.getDateFormatted(to);
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        setWarehouseLastYear: function () {
            var to = new Date();
            to.setFullYear(to.getFullYear() - 1);
            to.setMonth(12);
            to.setDate(0);
            var from = new Date();
            from.setFullYear(from.getFullYear() - 1);
            from.setMonth(0);
            from.setDate(1);
            this.toWarehouses = this.getDateFormatted(to);
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        setWarehouseActualMonth: function () {
            var to = new Date();
            to.setMonth(to.getMonth() + 1);
            to.setDate(0);
            this.toWarehouses = this.getDateFormatted(to);
            var from = new Date();
            from.setDate(1);
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        shadeColor: function (color, percent) {
            var R = parseInt(color.substring(1, 3), 16);
            var G = parseInt(color.substring(3, 5), 16);
            var B = parseInt(color.substring(5, 7), 16);

            R = parseInt(R * (100 + percent) / 100);
            G = parseInt(G * (100 + percent) / 100);
            B = parseInt(B * (100 + percent) / 100);

            R = (R < 255) ? R : 255;
            G = (G < 255) ? G : 255;
            B = (B < 255) ? B : 255;

            R = Math.round(R)
            G = Math.round(G)
            B = Math.round(B)

            var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
            var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
            var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

            return "#" + RR + GG + BB;
        }
    }
}
</script>

<style scoped lang="scss">
@media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
</style>
