<template>
    <div v-if="Object.keys(order).length !== 0" class="row p-5">
        <div class="col-12 px-md-5">
            <div class="row">
                <div class="col-12">
                    <div class="row py-4 px-0 px-sm-4 px-lg-0 align-items-center">
                        <div class="col-12">
                            <h2>{{ $t("orders.order") }} - {{ order.intern_id }}</h2>
                        </div>
                        <!-- Buttons -->
                        <div v-if="$store.getters.hasPerm('change_order')" class="col-12 d-block my-2">
                            <router-link :to="'/order/' + this.orderId + '/items'"
                                class="button-top float-left mr-1 me-2 btn" v-if="!order.hidden">{{
                                    $t("orders.editOrder") }}</router-link>
                            <button @click="copy()" class="button-top float-left mr-1 me-2 btn">{{
                                $t("orders.copyOrder") }}</button>
                            <button @click="done()" class="button-top float-left mr-1 me-2 btn"
                                v-if="!order.hidden && !order.done">{{
                                    $t("orders.orderFinished") }}</button>
                            <DeliveryNotePopup :orderId="this.orderId" />
                            <button @click="restore()" class="button-top float-left mr-1 me-2 btn"
                                v-if="order.hidden">{{
                                    $t("orders.restoreOrder") }}</button>
                            <button @click="hide()" class="button-top float-left mr-1 me-2 btn" v-if="!order.hidden">{{
                                $t("orders.hideOrder") }}</button>
                            <button @click="revenue()" class="button-top float-left mr-1 me-2 btn"
                                v-if="isOwner() && !order.collected_revenue_by">
                                <span>{{ $t("orders.collectedRevenue") }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <span>{{ $t("orders.orderFinished") }}:
                        <span v-if="order.done">{{ $t("orders.yes") }}</span>
                        <span v-else>{{ $t("orders.no") }}</span>
                    </span>
                </div>
                <div class="col-12 col-md-6">
                    <span>{{ $t("orders.orderReady") }}:
                        <span v-if="order.prepared || order.done">{{ $t("orders.yes") }}</span>
                        <span v-else>{{ $t("orders.no") }}</span>
                    </span>
                </div>
                <div class="col-12">
                    <span>{{ $t("orders.collectedRevenue") }}:
                        <span v-if="order.collected_revenue_by">{{ $t("orders.yes") }}</span>
                        <span v-else>{{ $t("orders.no") }}</span>
                    </span>
                </div>
                <!-- Customer -->
                <div class="col-12 col-md-6 mt-5">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ $t("customer.customer") }}</h3>
                        </div>
                        <div class="col-12">
                            <span>{{ $t("customer.firstName") }}: <b class="ms-1">{{ order.customer.first_name }} {{
                                order.customer.last_name
                                    }}</b></span>
                        </div>
                        <div v-if="order.customer.organization_id">
                            <div class="col-12">
                                <span>{{ $t("customer.organizationId") }}: <b class="ms-1">{{
                                    order.customer.organization_id
                                        }}</b></span>
                            </div>
                            <div class="col-12">
                                <span>{{ $t("customer.organizationName") }}: <b class="ms-1">{{
                                    order.customer.organization_name }}</b></span>
                            </div>
                        </div>
                        <div class="col-12">
                            <span>{{ $t("customer.phone") }}: <b><a :href="'tel:' + order.customer.whole_phone"
                                        class="phone-number link-darker ms-1">
                                        {{ order.customer.whole_phone }}</a></b>
                            </span>
                        </div>
                        <div v-if="order.customer.email" class="col-12">
                            <span>{{ $t("customer.email") }}: <b>
                                    <a :href="'mailto:' + order.customer.email" class="link-darker ms-1">
                                        {{ order.customer.email }}
                                    </a></b>
                            </span>
                        </div>
                        <div class="col-12">
                            <span>{{ $t("customer.address") }}:</span>
                            <span class="ms-1">
                                <b><a :href="order.customer.address.waze" class="link-darker" target="_blank">
                                        {{ order.customer.address.full_address }}
                                        <br><br>{{ $t("orders.wazeAddress") }}</a></b>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Intern info -->
                <div class="col-12 col-md-6 mt-5">
                    <h3>{{ $t("orders.internInfo") }}</h3>
                    <div class="row">
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.internNumber") }}</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ order.intern_id }}</b></div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.created") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ order.created }}</b></div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.expectedDateTime") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ order.expected_delivery_date }} {{
                            order.expected_delivery_time }}</b></div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.clientDistance") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b><span>{{ order.distance }} km ({{ order.duration }}
                                    {{ $t("orders.minutes") }})</span></b></div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.expectedTimeSpend") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ order.expected_delivery_duration }} {{
                            $t("orders.minutes") }}</b></div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.productPrice") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ formatNumber(order.product_prices, 2) }} {{
                            order.currency
                                }}</b></div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.deliveryPrice") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ formatNumber(delivery_price_total, 2) }} {{
                            order.currency }}</b>
                        </div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.priceNoVat") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ formatNumber(order.total_price, 2) }} {{ order.currency
                                }}</b>
                        </div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.priceVat") }}:</div>
                        <div class="col-12 col-md-6 mt-1"><b>{{ formatNumber(order.total_price_vat, 2) }} {{
                            order.currency
                                }}</b></div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.warehouse") }}:</div>
                        <div class="col-12 col-md-6 mt-1">
                            <b>
                                <span v-if="order.warehouse"><a :href="order.warehouse.address.waze" class="link-darker"
                                        target="_blank">{{ order.warehouse.name }}<br>{{
                                            order.warehouse.address.full_address }}</a></span>
                                <span v-else>{{ $t("orders.noWarehouse") }}</span>
                            </b>
                        </div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.driver") }}:</div>
                        <div class="col-12 col-md-6 mt-1">
                            <b>
                                <span v-if="order.driver">{{ order.driver.first_name }} {{ order.driver.last_name }}
                                    ({{ order.driver.username }})</span>
                                <span v-else>{{ $t("orders.personalCollect") }}</span>
                            </b>
                        </div>
                        <div v-if="order.car" class="col-12 col-md-6 mt-1">
                            <span>{{ $t("warehouses.car") }}:</span>
                        </div>
                        <div v-if="order.car" class="col-12 col-md-6 mt-1">
                            <span>{{ order.car.name }}</span>
                        </div>
                        <div class="col-12 col-md-6 mt-1">{{ $t("orders.note") }}:</div>
                        <div class="col-12 col-md-6 mt-1">
                            <span v-if="order.note">{{ order.note }}</span>
                            <span v-else>{{ $t("orders.noNote") }}</span>
                        </div>
                    </div>
                </div>
                <!-- Order items -->
                <div class="col-12 mt-5">
                    <h3>{{ $t("orders.items") }}</h3>
                    <div v-if="order.products" v-for="product in order.products" :key="product.id" class="row">
                        <div class="col-12 col-md-4">
                            <div class="panel panel-default card-input m-3 font-weight-bold"
                                style="opacity: 1; cursor: auto;">
                                <div class="panel-heading py-2 px-4 d-flex justify-content-between">
                                    <span>{{ product.product.product.name }}</span>
                                    <div v-if="!order.done && !product.prepared">
                                        <span @click="productReady(product.id)" class="float-right btn-product-ready">{{
                                            $t("orders.getReady") }}</span>
                                    </div>
                                    <div v-else>
                                        <span>{{ $t("orders.ready") }}</span>
                                    </div>
                                </div>
                                <div class="panel-body py-2 px-4">
                                    <span>{{ $t("products.type") }}: {{ product.product.product.type }}</span><br>
                                    <span>{{ $t("products.length") }}: {{ product.product.product.length }}
                                        cm</span><br>
                                    <span>{{ $t("products.amount") }}: {{ product.amount }} {{
                                        product.product.product.measurement_unit
                                    }}</span><br>
                                    <span>{{ $t("orders.ready") }}:
                                        <span v-if="product.prepared">{{ $t("orders.yes") }}</span>
                                        <span v-else>{{ $t("orders.no") }}</span>
                                    </span><br>
                                    <div v-if="$store.getters.hasPerm('change_order')">
                                        <span>{{ $t("products.unitPrice") }}: {{ formatNumber(product.product.amount, 2)
                                            }} {{ product.product.currency }}</span><br>
                                        <span>{{ $t("products.totalPrice") }}: {{ formatNumber(product.product.amount *
                                            product.amount, 2) }} {{ product.product.currency }}</span><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-12">{{ $t("orders.noItems") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DeliveryNotePopup from "@/components/DeliveryNotePopup.vue"

export default {
    components: {
        DeliveryNotePopup
    },
    data() {
        return {
            order: {},
            warehouse_car: {},
            orderId: null,
        }
    },
    created() {
        this.orderId = this.$route.params.id;
        this.loadData();
    },
    computed: {
        delivery_price_total: function () {
            if (this.order == {}) {
                return 0;
            }
            var price = this.order.delivery_price.amount * this.order.distance * 2;
            if (price < this.order.delivery_price.minimum_price) {
                price = this.order.delivery_price.minimum_price;
            }
            return price;
        }
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get("/order/" + this.orderId).then((response) => {
                this.order = response.data.item;
            })
        },
        copy: function () {
            this.$store.getters.api.get("/order/" + this.orderId + "/copy").then((response) => {
                this.$router.push("/order/" + response.data.order_id + "/items");
            })
        },
        restore: function () {
            this.$store.getters.api.put("/order/" + this.orderId + "/restore").then(function () {
                this.loadData();
            }.bind(this))
        },
        done: function () {
            this.$store.getters.api.put("/order/" + this.orderId + "/done").then(function () {
                this.loadData();
            }.bind(this))
        },
        hide: function () {
            this.$store.getters.api.delete("/order/" + this.orderId + "/delete").then(function () {
                this.loadData();
            }.bind(this))
        },
        revenue: function () {
            this.$store.getters.api.put("/order/" + this.orderId + "/collect_revenue").then((response) => {
                this.loadData();
            })
        },
        productReady: function (product_id) {
            this.$store.getters.api.put("/order/" + this.orderId + "/" + product_id + "/prepared").then(function () {
                this.loadData();
            }.bind(this))
        },
        isOwner: function () {
            var owner = false;
            this.$store.state.user.functions.forEach(element => {
                if (element.name == "owner") {
                    owner = true;
                }
            })
            return owner;
        },
    },
}
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.button-top {
    background-color: $primary;
    color: $white;
}

.btn-product-ready {
    border: 1px solid transparent;
    background-color: $primary;
    color: $white;
    padding: 5px 10px;
    border-radius: 4px;
    color: white;

    &:hover {
        color: black;
        border: 1px solid $primary;
        background-color: transparent;
    }
}

.panel {
    border: 2px solid $primary;
    border-radius: 25px;
    font-weight: bold;

    .panel-heading {
        border-bottom: 1px solid $primary;
    }
}
</style>