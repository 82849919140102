<template>
    <VueMultiselect v-model="text"
                    :options="addresses"
                    :searchable="true"
                    :internalSearch="false"
                    :preserveSearch="true"
                    :multiple="false"
                    @select="selectAddress"
                    @search-change="getAddresses"
                    label="address"
                    placeholder="Korunní 2569/108, Praha 10, 110 00"
                    class="w-100 multiselect" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')"
                    :deselect-label="$t('multiselect.pressToDeselect')"
                    :selected-label="$t('multiselect.selected')">
        <template #noOptions>
            <span>{{ $t("multiselect.noOptions") }}</span>
        </template>
        <template #noResult>
            <span>{{ $t("multiselect.noResult") }}</span>
        </template>
    </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import axios from "axios";
import { CONFIG } from "@/config.js";
export default {
    components: {
        VueMultiselect,
    },
    props: {
        getData: {
            type: Function
        }
    },
    data: function(){
        return {
            text: null,
            lat: null,
            lot: null,
            zip: null,
            town: null,
            country: null,
            address: null,
            addresses: [],
            timeout: null
        }
    },
    methods: {
        sendData: function () {
            this.getData(this.lat, this.lot, this.address, this.zip, this.town, this.country);
        },
        selectAddress: function (selectedOption, id) {
            var selected = selectedOption;
            if (selected.position) {
                this.lot = selected.position.lon;
                this.lat = selected.position.lat;
            }
            var street = "";
            var address = ""
            for (var i = 0; i < selected.regionalStructure.length; i++){
                var data = selected.regionalStructure[i];
                if (data.type == "regional.street") {
                    street = data.name
                }
                if (data.type == "regional.address") {
                    address = data.name
                }
                if (data.type == "regional.municipality") {
                    this.town = data.name
                }
                if (data.type == "regional.country") {
                    this.country = data.name
                }
            }
            if (selected.zip) {
                this.zip = selected.zip;
            }
            if (street || address) {
                if (street && address) {
                    this.address = street + " " + address;
                } else if (street) {
                    this.address = street;
                } else {
                    this.address = address;
                }
            }
            this.sendData();
        },
        getAddresses: function (searchQuery, id) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            if (!searchQuery) {
                this.addresses = [];
                return;
            }
            this.timeout = setTimeout(function () {
            axios.get("https://api.mapy.cz/v1/suggest", {
                params: {
                    query: searchQuery,
                    lang: "cs",
                    limit: 5,
                    type: "regional",
                    type: "poi",
                    type: "coordinate",
                    apikey: CONFIG.seznamAPIkey
                }
            }).then(function (response) {
                this.addresses = response.data.items;
                for (var i = 0; i < this.addresses.length; i++){
                    var selected = this.addresses[i];
                    var street = "";
                    var address = "";
                    var town = "";
                    var country = "";
                    var zip = "";
                    for (var j = 0; j < selected.regionalStructure.length; j++){
                        var data = selected.regionalStructure[j];
                        if (data.type == "regional.street") {
                            street = data.name
                        }
                        if (data.type == "regional.address") {
                            address = data.name
                        }
                        if (data.type == "regional.municipality") {
                            town = data.name
                        }
                        if (data.type == "regional.country") {
                            country = data.name
                        }
                    }
                    if (selected.zip) {
                        zip = selected.zip;
                    }
                    var addStreet = street + " " + address;
                    var town = town + " " + zip;
                    var fullAddress = addStreet.trim() + ", " + town.trim();
                    if (country) {
                        fullAddress += ", " + country;
                    }
                    this.addresses[i].address = fullAddress.trim();
                }
                console.log(this.addresses[0])
            }.bind(this))}.bind(this), 1000);
        }
    }
}
</script>