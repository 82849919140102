<template>
    <div class="p-5 row">
        <div class="col-12">
            <h2>{{ $t("products.changePrice") }}</h2>
        </div>
        <div class="col-12">
            <h3>{{ $t("products.description") }}</h3>
        </div>
        <div class="col-12">
            <p>{{ $t("products.shortcut") }}: {{ product.shorten_name }}</p>
        </div>
        <div class="col-12">
            <p>{{ $t("products.name") }}: {{ product.name }}</p>
        </div>
        <div class="col-12">
            <p>{{ $t("products.type") }}: {{ product.type }}</p>
        </div>
        <div class="col-12">
            <p>{{ $t("products.length") }}: {{ product.length }} cm</p>
        </div>
        <div class="col-12">
            <p>{{ $t("products.currentVatPrice") }}: {{ $t("products.from") }}
                <span v-if="product.pricesWithVat" v-for="(price, index) in product.pricesWithVat" :key="index"
                    class="me-2"> {{ price.amount }} {{ price.currency }}</span>
                <span v-else>--</span>
            </p>
        </div>
        <div class="col-12">
            <ProductPriceForm :warehouses="warehouses" :productId="productId" />
        </div>
    </div>
</template>
<script>
import ProductPriceForm from "@/components/ProductPriceForm.vue";

export default {
    components: {
        ProductPriceForm
    },
    data() {
        return {
            warehouses: [],
            productId: null,
            product: {}
        }
    },
    created: function () {
        this.productId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/product/" + this.productId).then((response) => {
                this.product = response.data.item;
            });
            this.$store.getters.api.get("/warehouses").then((response) => {
                this.warehouses = response.data.items;
            });
        },
    }
}
</script>
<style lang="">

</style>