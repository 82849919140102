<template>
    <div>
        <div class="day-heading">
            <div class="weekday">{{ dayShortName[selectedDateCalendar.date.getDay()] }}</div>
            <div class="date">{{ selectedDateCalendar.date.getDate() }}</div>
        </div>
        <div :id="'day' + selectedDateCalendar.date.getDate()" class="hours-column">
            <div v-for="i in (calendarTo - calendarFrom) * 4" class="quarter-hour-day-row">
            </div>
            <div class="actual-time-line" v-if="isTodayFullDate(selectedDateCalendar.date) && isInSelectedTime()"
                :style="{ top: positionTime }">
            </div>
            <div class="actual-time-point" v-if="isTodayFullDate(selectedDateCalendar.date) && isInSelectedTime()"
                :style="{ top: positionTime }">
            </div>
            <div v-for="order in dayOrders" :key="order.id">
                <div v-if="showTravelTime" :key="order.id"
                    :style="{ top: positionBeforeOrderTravel(order), height: maxHeight(deliveryHeight(order), positionBeforeOrderTravel(order)), backgroundColor: transparentHexColor(order.warehouse.color) }"
                    class="calendar-order" @click="selectOrder(order)">
                </div>
                <div v-if="maxHeight(heightOrder(order), positionOrder(order)) != '0%'">
                    <div :key="order.id"
                        :style="{ top: positionOrder(order), height: maxHeight(heightOrder(order), positionOrder(order)), backgroundColor: order.warehouse.color }"
                        class="calendar-order calendar-order-main" @click="selectOrder(order)">
                        <div class="d-flex justify-content-center aling-items-center p-2">
                            <span class="order-number" v-if="showIds">{{ order.intern_id }}</span>
                            <span class="order-number" v-else>{{ order.calendar_name }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="showTravelTime" :key="order.id"
                    :style="{ top: positionAfterOrderTravel(order), height: maxHeight(deliveryHeight(order), positionAfterOrderTravel(order)), backgroundColor: transparentHexColor(order.warehouse.color) }"
                    class="calendar-order" @click="selectOrder(order)">
                </div>
            </div>
            <div v-for="vacation in vacations" :key="vacation.id">
                <div v-if="maxHeight(heightVacation(vacation), positionVacation(vacation)) != '0%'">
                    <div :key="vacation.id"
                        :style="{ top: positionVacation(vacation), height: maxHeight(heightVacation(vacation), positionVacation(vacation)), backgroundColor: vacation.warehouse.color, color: vacation.warehouse.text_color }"
                        class="calendar-order calendar-order-main">
                        <div class="d-flex justify-content-center aling-items-center p-2"
                            :style="{ top: textPosition(heightVacation(vacation), positionVacation(vacation)), position: 'relative' }">
                            <span class="order-number">{{ $t("vacations.vacation") }} - {{ vacation.warehouse.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        orders: {
            type: Array, // Adjust the type based on your data
            required: true,
        },
        vacations: {
            type: Array, // Adjust the type based on your data
            required: true,
        },
        selectedDate: {
            type: Date,
            required: true,
        },
        selectedOrderOutside: {
            type: Object,
            default: {},
            required: false,
        },
        showIds: Boolean,
        calendarFrom: {
            type: Number,
            default: 6,
        },
        calendarTo: {
            type: Number,
            default: 18,
        },
        showTravelTime: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            today: new Date(),
            currentYear: null,
            currentMonth: null,
            updateTimeInterval: null,
            orderTimeout: null,
            selectedOrder: null,
            showOneDay: false,
            months: [
                "Leden",
                "Únor",
                "Březen",
                "Duben",
                "Květen",
                "Červen",
                "Červenec",
                "Srpen",
                "Září",
                "Říjen",
                "Listopad",
                "Prosinec"
            ],
            dayShortName: [
                "Ne",
                "Po",
                "Út",
                "St",
                "Čt",
                "Pá",
                "So"
            ]
        }
    },
    created: function () {
        this.currentMonth = this.today.getMonth();
        this.currentYear = this.today.getFullYear();
        this.updateSelectedWeekBySelectedDate();

        // interval to update time
        this.updateTimeInterval = setInterval(function () {
            this.today = new Date();
        }.bind(this), 60000);

    },
    watch: {
        calendarFrom: function (value) {
            if (this.calendarFrom > this.calendarTo) {
                this.calendarFrom = this.calendarTo - 1;
                return
            }
            this.calendarFrom = value;
        },
        calendarTo: function (value) {
            if (this.calendarTo < this.calendarFrom) {
                this.calendarTo = this.calendarFrom + 1;
                return
            }
            this.calendarTo = value;
        },
        selectedDate: function () {
            this.updateSelectedWeekBySelectedDate();
        }
    },
    computed: {
        dayOrders: function () {
            var tmpOrders = []
            this.orders.forEach((el) => { tmpOrders.push(el) });
            if (Object.keys(this.selectedOrderOutside).length != 0) {
                tmpOrders.push(this.selectedOrderOutside);
            }
            return tmpOrders;
        },
        daysInMonth: function () {
            return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        },
        selectedWeekNumber: function () {
            var startDate = new Date(this.selectedDate.getFullYear(), 0, 1);
            var days = Math.floor((this.selectedDate - startDate) /
                (24 * 60 * 60 * 1000)) + 1;

            return Math.ceil(days / 7) + 1;
        },
        selectedWeekEven: function () {
            return this.selectedWeekNumber % 2 ? "lichý" : "sudý";
        },
        daysInPreviosMonth: function () {
            var actualStart = new Date(this.currentYear, this.currentMonth, 1).getDay();
            // remove number of days
            if (actualStart == 0) {
                actualStart = 6
            }
            else {
                actualStart -= 1
            }
            var previousMonth = this.currentMonth - 1;
            var previousYear = this.currentYear;
            if (previousMonth <= -1) {
                previousMonth = 11;
                previousYear -= 1;
            }

            // create date list
            dates = []
            maxDates = new Date(previousYear, previousMonth + 1, 0).getDate();
            for (var i = maxDates - actualStart + 1; i <= maxDates; i++) {
                dates.push(i)
            }

            return dates
        },
        daysInNextMonth: function () {
            var actualEnd = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
            // remove number of days
            actualEnd = new Date(this.currentYear, this.currentMonth, actualEnd).getDay();
            if (actualEnd == 0) {
                actualEnd = 7;
            }
            // create date list
            dates = []
            for (var i = 1; i <= 7 - actualEnd; i++) {
                dates.push(i)
            }

            return dates
        },
        positionTime: function () {
            return this.positionItem(this.today);
        },
        selectedDateCalendar: function () {
            for (var i = 0; i < this.selectedWeek.length; i++) {
                if (this.sameDay(this.selectedWeek[i].date, this.selectedDate)) {
                    return this.selectedWeek[i];
                }
            }
        }
    },
    methods: {
        getOrders: function () {
            if (this.orderTimeout) {
                clearTimeout(this.orderTimeout);
            }
            this.orderTimeout = setTimeout(function () {
                var that = this;
                var dates = []
                for (var i = 0; i < this.selectedWeek.length; i++) {
                    var tmp = this.selectedWeek[i].date;
                    var date = tmp.getFullYear() + "-" + (tmp.getMonth() + 1).toString().lpad("0", 2) + "-" + tmp.getDate().toString().lpad("0", 2);
                    dates.push(date)
                }
                this.$store.getters.api.get('/api/get-orders', {
                    params: {
                        warehouses: this.selectedWarehouses,
                        dates: [dates]
                    }
                }).then(function (response) {
                    var data = response.data.data;
                    that.orders = [];
                    for (var x = 0; x < that.selectedWeek.length; x++) {
                        for (var i = 0; i < data.length; i++) {
                            var order_date = new Date(data[i].expected_delivery_date);
                            if (order_date.toDateString() === that.selectedWeek[x].date.toDateString()) {
                                that.orders.push(data[i]);
                            }
                        }
                    }
                });
                this.$store.getters.api.get('/api/get-vacations', {
                    params: {
                        warehouses: this.selectedWarehouses,
                        dates: dates
                    }
                }).then(function (response) {
                    var data = response.data.data;
                    that.vacations = [];
                    for (var x = 0; x < that.selectedWeek.length; x++) {
                        for (var i = 0; i < data.length; i++) {
                            var order_date = new Date(data[i].date);
                            if (order_date.toDateString() === that.selectedDate.toDateString()) {
                               that.vacations.push(data[i]);
                            }
                        }
                    }
                });
            }.bind(this), 500);
        },
        previousMonth: function () {
            this.currentMonth -= 1;
            if (this.currentMonth <= -1) {
                this.currentMonth = 11;
                this.currentYear -= 1;
            }
        },
        nextMonth: function () {
            this.currentMonth += 1;
            if (this.currentMonth >= 12) {
                this.currentMonth = 0;
                this.currentYear += 1;
            }
        },
        updateCurrentMonthYear: function () {
            this.currentMonth = this.selectedDate.getMonth();
            this.currentYear = this.selectedDate.getFullYear();
        },
        isSelected: function (i) {
            return i == this.selectedDate.getDate()
                && this.selectedDate.getMonth() == this.currentMonth
                && this.selectedDate.getFullYear() == this.currentYear;
        },
        isToday: function (i) {
            this.today = new Date();
            return !this.isSelected(i) && i == this.today.getDate()
                && this.today.getMonth() == this.currentMonth
                && this.today.getFullYear() == this.currentYear;
        },
        updateSelectedWeekBySelectedDate: function () {
            var dates = []

            var date = this.selectedDate;
            var weekDay = date.getUTCDay();
            for (var i = weekDay; i >= 0; i--) {
                dates.push(new Date(date.getFullYear(), date.getMonth(), date.getDate() - i))
            }
            for (var i = 1; i < (7 - weekDay); i++) {
                dates.push(new Date(date.getFullYear(), date.getMonth(), date.getDate() + i))
            }

            this.selectedWeek = []
            for (var i = 0; i < dates.length; i++) {
                this.selectedWeek.push({ date: dates[i], orders: [] })
            }
        },
        isTodayFullDate: function (date) {
            return this.sameDay(this.today, date);
        },
        isInSelectedTime: function () {
            var hours = this.today.getHours();
            if (hours >= this.calendarFrom && hours < this.calendarTo) {
                return true;
            }
            return false;
        },
        positionInCalendar: function (minutesPassed) {
            var totalDayMinutes = (this.calendarTo - this.calendarFrom) * 60;
            return ((minutesPassed / totalDayMinutes) * 100) + "%"
        },
        positionItem: function (datetime) {
            var totalMinutesPassed = (datetime.getHours() - this.calendarFrom) * 60 + datetime.getMinutes();
            return this.positionInCalendar(totalMinutesPassed);
        },
        positionOrder: function (order) {
            if (order.expected_delivery_datetime) {
                var date = new Date(order.expected_delivery_datetime)
                return this.positionItem(date);
            }
            if (order.expected_delivery_date && order.expected_delivery_time) {
                var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time)
                return this.positionItem(date);
            }
        },
        positionVacation: function (vacation) {
            if (vacation.date && vacation.from_time) {
                var date = new Date(vacation.date + " " + vacation.from_time);
                return this.positionItem(date);
            }
        },
        positionBeforeOrderTravel: function (order) {
            if (order.expected_delivery_datetime) {
                var date = new Date(order.expected_delivery_datetime)
                date = this.addMinutes(date, (-1) * order.duration);
                return this.positionItem(date);
            }
            if (order.expected_delivery_date && order.expected_delivery_time) {
                var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time)
                date = this.addMinutes(date, (-1) * order.duration);
                return this.positionItem(date);
            }
        },
        positionAfterOrderTravel: function (order) {
            if (order.expected_delivery_datetime) {
                var date = new Date(order.expected_delivery_datetime)
                date = this.addMinutes(date, order.expected_delivery_duration);
                return this.positionItem(date);
            }
            if (order.expected_delivery_date && order.expected_delivery_time) {
                var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time)
                date = this.addMinutes(date, order.expected_delivery_duration);
                return this.positionItem(date);
            }
        },
        heightOrder: function (order) {
            return this.positionInCalendar(order.expected_delivery_duration);
        },
        heightVacation: function (vacation) {
            return this.positionInCalendar(vacation.duration);
        },
        deliveryHeight: function (order) {
            return this.positionInCalendar(order.duration);
        },
        orderRedirect: function (order) {
            window.location.replace("/order/" + order.id);
        },
        sameDay: function (date1, date2) {
            return date1.getFullYear() == date2.getFullYear()
                && date1.getMonth() == date2.getMonth()
                && date1.getDate() == date2.getDate();
        },
        addMinutes: function (date, minutes) {
            return new Date(date.getTime() + minutes * 60000);
        },
        transparentHexColor: function (color) {
            // transparent hex color
            return color + 'cc';
        },
        selectOrder: function (order) {
            this.selectedOrder = order;
            this.$emit("selectOrder", order);
        },
        deselectOrder: function () {
            this.selectedOrder = null;
        },
        orderDateFormatter: function (order) {
            var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time);
            return date.toLocaleString();
        },
        priceFormatter: function (price) {
            return new Intl.NumberFormat("cs-cz", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
        },
        maxHeight: function (height, pos) {
            var n_hieght = parseFloat(height.replace("%", ""));
            var n_pos = parseFloat(pos.replace("%", ""));
            if (n_pos + n_hieght >= 100) {
                var possible_height = 100 - n_pos;
                if (possible_height <= 0) {
                    return "0%";
                }

                return possible_height + "%";
            }
            return n_hieght + "%";
        },
        textPosition: function (height, pos) {
            var n_hieght = parseFloat(height.replace("%", ""));
            var n_pos = parseFloat(pos.replace("%", ""));
            if (n_pos + n_hieght >= 100) {

                return "calc(" + Math.abs(n_pos * 1.5) + "% - 70px)";
            }
            return "calc(50% - 70px)";
        },
        overlapingOrders: function () {
            // get orders and remove seeking one
            var orders = this.orders;
            var vacations = this.vacations;
            // get order dates
            var date = new Date(this.selectedDate);
            var orderStart = this.addMinutes(date, (-1) * this.selectedOrderOutside.duration);
            var orderEnd = this.addMinutes(date, parseInt(this.selectedOrderOutside.expected_delivery_duration) + parseInt(this.selectedOrderOutside.duration));

            for (var i = 0; i < orders.length; i++){
                var order = orders[i];
                var date_string = order.expected_delivery_date + "T" + order.expected_delivery_time;
                var d = new Date(date_string);
                var tmpStart = this.addMinutes(d, (-1) * order.duration);
                var tmpEnd = this.addMinutes(d, order.expected_delivery_duration + order.duration);

                if (orderStart >= tmpStart && orderStart <= tmpEnd) {
                    return true;
                }
                if (orderEnd >= tmpStart && orderEnd <= tmpEnd) {
                    return true;
                }
            }

            for (var i = 0; i < vacations.length; i++){
                var vacation = vacations[i];
                var date_string = vacation.date + "T" + vacation.from_time;
                var d = new Date(date_string);
                var tmpStart = this.addMinutes(d, (-1) * vacation.duration);
                var tmpEnd = this.addMinutes(d, vacation.duration);

                if (orderStart >= tmpStart && orderStart <= tmpEnd) {
                    return true;
                }
                if (orderEnd >= tmpStart && orderEnd <= tmpEnd) {
                    return true;
                }
            }

            return false;
        },
    }
}
</script>
<style lang="">

</style>