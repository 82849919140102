// common configuration
const config = {
    api: {
        baseUrl: "https://system.drevo-hosek.cz/api"
    },
    afterLoginRoute: "dashboard",
    vueAppUrl: "https://system.drevo-hosek.cz/",
    seznamAPIkey: "vDDn3L5W7ZhRW9u7EowHsICpRmY_vEBMIqU00aGYydo"
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
    config["vueAppUrl"] = "https://system.drevo-hosek.cz/";
    config["api"]["baseUrl"] = "https://system.drevo-hosek.cz/api";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "staging") {
    config["vueAppUrl"] = "https://staging.system.drevo-hosek.cz/";
    config["api"]["baseUrl"] = "https://staging.system.drevo-hosek.cz/api";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://localhost:10001";
    config["api"]["baseUrl"] = "http://localhost:10000";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_michal") {
    config["vueAppUrl"] = "http://65.108.224.246:35101";
    config["api"]["baseUrl"] = "http://65.108.224.246:35100";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_tomas") {
    config["vueAppUrl"] = "http://localhost:30001";
    config["api"]["baseUrl"] = "http://localhost:30000";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_kuba") {
    config["vueAppUrl"] = "http://localhost:20101";
    config["api"]["baseUrl"] = "http://localhost:20100";
}

export const CONFIG = config;