<template>
    <button class="button-top float-left mr-1 me-2 btn" data-bs-toggle="modal" data-bs-target="#deliveryNote">
        <span>{{ $t("orders.makeDeliveryNote") }}</span>
    </button>
    <div class="modal fade" id="deliveryNote" tabindex="-1" aria-labelledby="deliveryNoteLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deliveryNoteLabel">{{ $t('orders.makeDeliveryNote') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3 w-50">
                        <label for="deliveryNoteDate" class="form-label">{{ $t('orders.supplier') }}</label>
                        <select class="form-select" v-model="postData.supplier" id="supplier">
                            <option v-for="supplier in suppliers" :value="supplier.id">
                                {{ supplier.name }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3 w-50">
                        <label for="deliveryNoteDate" class="form-label">{{ $t('orders.language') }}</label>
                        <select class="form-select" v-model="postData.language" id="language">
                            <option value="cs">
                                Čeština
                            </option>
                            <option value="de">
                                Němčina
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <span>{{ $t('utils.close') }}</span>
                    </button>
                    <button type="button" class="btn btn-primary" @click="get_delivery_note()">
                        <span>{{ $t('utils.submit') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        orderId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            suppliers: [],
            postData: {
                supplier: null,
                language: null
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        get_delivery_note: function () {
            const config = {
                params: this.postData,
                responseType: 'blob'
            };
            this.$store.getters.api.get("/order/" + this.orderId + "/get_delivery_note", config)
                .then((response) => {
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    const href = URL.createObjectURL(blob);

                    // Create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;

                    // Generate a default filename
                    const filename = `dodaci_list_${this.orderId}.pdf`;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    // Clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
        },
        loadData() {
            this.$store.getters.api.get("/suppliers").then((response) => {
                this.suppliers = response.data.items;
            })
        }
    }
}
</script>
<style lang="">

</style>