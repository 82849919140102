<template>
    <div class="calendar-order-detail" @click="deselectOrder()">
        <div class="calendar-order-detail-card" @click.stop="">
            <div class="row">
                <div class="col-10">
                    <h2>{{ selectedOrder.intern_id }}</h2>
                </div>
                <div class="col-2 cursor-pointer">
                    <div @click="deselectOrder()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="44"
                            height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </div>
                </div>
            </div>

            <h3>{{ $t("orders.internInfo") }}</h3>
            <p class="my-1">{{ $t("orders.dateAndTimeDelivery") }}: {{ orderDateFormatter(selectedOrder) }}</p>
            <p class="my-1">{{ $t("addOrder.distance") }}: {{ selectedOrder.expected_travel_length }} km {{
                selectedOrder.duration }} min.</p>
            <p class="my-1">{{ $t("orders.warehouse") }}: {{ selectedOrder.warehouse.name }}</p>
            <p class="my-1">{{ $t("functions.driver") }}: {{ selectedOrder.driver }}</p>
            <p v-if="selectedOrder.warehouse_car" class="my-1">
                <span>{{ $t("warehouses.car") }}:</span> <span>{{ selectedOrder.warehouse_car.name }}</span>
            </p>

            <h3 class="mt-4">{{ $t("customer.customer") }}</h3>
            <p v-if="selectedOrder.address" class="my-1">{{ $t("orders.address") }}: {{ selectedOrder.address }}</p>
            <p v-else class="my-1">{{ $t("orders.address") }}: {{ selectedOrder.customer.address }}</p>
            <p class="my-1">{{ $t("orders.phone") }}: {{ selectedOrder.customer.phone }}</p>
            <p class="my-1">{{ $t("orders.note") }}: {{ selectedOrder.note }} </p>

            <h3 class="mt-4">{{ $t("orders.goods") }}</h3>
            <div class="row">
                <span v-for="product in selectedOrder.products" class="col-12 my-1">{{ product.name }} - {{
                    product.amount
                }} {{ product.unit }}</span>
            </div>

            <p class="my-2"><b>{{ $t("orders.priceVat") }}</b>: {{ formatNumber(selectedOrder.price_with_vat, 2) }} {{
                selectedOrder.currency }} </p>
            <div class="row my-3">
                <div class="col-12 col-md-6">
                    <router-link :to="`/order/${selectedOrder.id}`" class="btn btn-primary w-100 form-submit m-2">{{
                        $t("utils.detail") }}</router-link>
                </div>
                <div v-if="$store.getters.hasPerm('change_order')" class="col-12 col-md-6">
                    <router-link :to="`/order/${selectedOrder.id}/items`"
                        class="btn btn-primary w-100 form-submit m-2 mb-2">{{ $t("utils.edit") }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedOrder: {
            type: Object,
            required: true,
        },
        warehouse_car: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
        }
    },
    computed: {

    },
    methods: {
        selectOrder: function (order) {
            this.selectedOrder = order;
        },
        deselectOrder: function () {
            this.$emit('update:selectedOrder', null);
        },
        orderDateFormatter: function (order) {
            var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time);
            return date.toLocaleString();
        }
    }
}
</script>
<style lang="">

</style>