<template>
    <div class="">
        <div>
            <h2>{{ $t("customer.createProfile") }}</h2>
        </div>
        <div class="row">
            <div class="col-6">
                <label for="first_name" class="mt-2">{{ $t("customer.firstName") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <label for="last_name" class="mt-2">{{ $t("customer.lastName") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <input type="text" class="form-control" v-model="formData.firstName" placeholder="Matěj">
            </div>
            <div class="col-6">
                <input type="text" class="form-control" v-model="formData.lastName" placeholder="Marek">
            </div>
            <div class="col-4">
                <label for="organization_id" class="mt-2">{{ $t("customer.organizationId") }}</label>
            </div>
            <div class="col-8">
                <label for="organization_name" class="mt-2">{{ $t("customer.organizationName") }}</label>
            </div>
            <div class="col-4">
                <input type="text" class="form-control" v-model="formData.organizationId" placeholder="03804445">
            </div>
            <div class="col-8">
                <input type="text" class="form-control" v-model="formData.organizationName" placeholder="Les Profi s.r.o.">
            </div>
            <div class="col-12 mt-4">
                <label for="phone" class="mt-2">{{ $t("customer.phone") }}<span>*</span></label>
            </div>
            <div class="col-3">
                <select name="pre_phone" id="pre_phone" class="form-control  form-select" v-model="formData.phonePrefix">
                    <option v-for="(prefix, i) in phonePrefixes" v-key="i" :value="prefix.code">
                        {{ prefix.code }}
                    </option>
                </select>
            </div>
            <div class="col-9">
                <input type="phone" class="form-control" v-model="formData.phone" placeholder="736 109 281">
            </div>
            <div class="col-12">
                <label for="email" class="mt-2">{{ $t("customer.email") }}</label>
            </div>
            <div class="col-12">
                <input type="email" class="form-control" v-model="formData.email" placeholder="marek@digitalwish.cz">
            </div>
            <div class="col-12 mt-4">
                <label for="address" class="mt-2">{{ $t("customer.findAddress") }}</label>
            </div>
            <div class="col-12">
                <AddressFinderNew :getData="addressData" />
            </div>
            <div class="col-6">
                <label for="final_address" class="mt-2">{{ $t("customer.address") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <label for="town" class="mt-2">{{ $t("customer.town") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <input type="text" class="form-control" v-model="formData.address" placeholder="Korunní 2569/108">
            </div>
            <div class="col-6">
                <input type="text" class="form-control" v-model="formData.town" placeholder="Praha 10">
            </div>
            <div class="col-3">
                <label for="zip" class="mt-2">{{ $t("customer.zip") }}<span>*</span></label>
            </div>
            <div class="col-9">
                <label for="country" class="mt-2">{{ $t("customer.state") }}<span>*</span></label>
            </div>
            <div class="col-3">
                <input type="text" class="form-control" v-model="formData.zip" placeholder="11000">
            </div>
            <div class="col-9">
                <input type="text" class="form-control" v-model="formData.country" placeholder="Česká republika">
            </div>
            <div class="col-6">
                <label for="lon" class="mt-2">{{ $t("customer.lon") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <label for="lat" class="mt-2">{{ $t("customer.lat") }}<span>*</span></label>
            </div>
            <div class="col-6">
                <input type="text" class="form-control" v-model="formData.lat" placeholder="49,2131798130803">
            </div>
            <div class="col-6">
                <input type="text" class="form-control" v-model="formData.lon" placeholder="17,5175885924409">
            </div>
            <button @click="submitForm()" class="mt-4 btn btn-primary w-100 form-submit">
                {{ $t("utils.create") }}
            </button>
        </div>
    </div>
</template>

<script>
import AddressFinderNew from "@/components/AddressFinderNew.vue";
export default {
    components: {
        AddressFinderNew
    },
    props: {
        redirectTo: {
            type: String,
            required: false,
            default: '/customers'
        },
        endpoint: {
            type: String,
            required: false,
            default: '/customer/add'
        },
    },
    data() {
        return {
            lat: null,
            lon: null,
            address: null,
            zip: null,
            town: null,
            country: null,
            phonePrefixes: [],
            phonePrefix: null,
            email: null,
            phone: null,
            firstName: null,
            lastName: null,
            organizationId: null,
            organizationName: null,
            formData: {
                lat: null,
                lon: null,
                address: null,
                zip: null,
                town: null,
                country: null,
                phonePrefix: '+420',
                email: null,
                phone: null,
                firstName: null,
                lastName: null,
                organizationId: null,
                organizationName: null
            }
        }
    },
    created: function () {
        this.loadData();
    },
    watch: {
        "formData.country": {
            handler: function (val) {
                if ((val == "Österreich" || val == "Rakousko") && this.formData.phonePrefix != '+43') {
                    if (confirm("Chcete změnit předvolbu na +43?")) {
                        this.formData.phonePrefix = '+43';
                    }
                }
                if ((val == "Česko" || val == "Česká republika") && this.formData.phonePrefix != '+420') {
                    if (confirm("Chcete změnit předvolbu na +420?")) {
                        this.formData.phonePrefix = '+420';
                    }
                }
                if ((val == "Slovensko" || val == "Slovenská republika") && this.formData.phonePrefix != '+421') {
                    if (confirm("Chcete změnit předvolbu na +421 ?")) {
                        this.formData.phonePrefix = '+421';
                    }
                }
            },
            deep: true,
            immediate: false
        },
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get("/phone_prefixes").then(function (response) {
                this.phonePrefixes = response.data.items;
            }.bind(this));
        },
        submitForm() {
            this.$store.getters.api.post(this.endpoint, this.formData).then(function (response) {
                this.$router.push(this.redirectTo)
            }.bind(this));
        },
        addressData: function (lat, lon, address, zip, town, country) {
            this.formData.lat = lat;
            this.formData.lon = lon;
            this.formData.address = address;
            this.formData.zip = zip;
            this.formData.town = town;
            this.formData.country = country;
        }
    }
}
</script>
<style lang="">

</style>