<template>
    <div class="row p-2 p-lg-5" id="overviews">
        <div class="col-12 px-2 px-lg-5">
            <CampaingsOverviews :warehouses="warehouses" />
            <ProductsExportOver class="my-4" :warehouses="warehouses" />
            <CustomersExportOver class="my-4" :warehouses="warehouses" />
            <WarehousesOver :warehouses="warehouses" />
            <ProductionOver class="my-4" :warehouses="warehouses" />
            <CollectedRevenueOver class="my-4" :warehouses="warehouses"/>
        </div>
    </div>
</template>
<script>
import WarehousesOver from "@/components/overviews/WarehousesOver.vue";
import ProductionOver from "@/components/overviews/ProductionOver.vue";
import CampaingsOverviews from "@/components/overviews/CampaingsOverviews.vue";
import CollectedRevenueOver from "@/components/overviews/CollectedRevenueOver.vue";
import ProductsExportOver from "@/components/overviews/ProductsExportOver.vue";
import CustomersExportOver from "@/components/overviews/CustomersExportOver.vue";

export default {
    components: {
        WarehousesOver,
        ProductionOver,
        CampaingsOverviews,
        CollectedRevenueOver,
        ProductsExportOver,
        CustomersExportOver
    },
    data: function () {
        return {
            warehouses: []
        }
    },
    created: function () {
        this.getWarehouses();
    },
    methods: {
        getWarehouses: function () {
            this.$store.getters.api.get("/warehouses").then((response) => {
                this.warehouses = response.data.items;
            });
        }
    }
}
</script>
<style lang="">

</style>