<template>
    <div class="mb-5">
        <h3>{{ $t("overviews.customersOverview") }}</h3>
        <div class="row mb-3 align-items-center">
            <div class="col-2">
                <label class="control-label">{{ $t("overviews.from") }}</label>
            </div>
            <div class="col-2">
                <label class="control-label">{{ $t("overviews.to") }}</label>
            </div>
            <div class="col-4 col-lg-2">
                <label class="control-label">{{ $t("overviews.warehouse") }}</label>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-2">
                <input type="date" v-model="from" :max="to" class="form-control" />
            </div>
            <div class="col-2">
                <input type="date" v-model="to" :min="from" class="form-control" />
            </div>
            <div class="col-4 col-lg-2">
                <select v-model="selectedWarehouse" style="width: 100%" class="form-select">
                    <option :value="null">{{ $t("overviews.all") }}</option>
                    <option v-for="warehouse in warehouses" :value="warehouse.id" :key="warehouse.id">{{ warehouse.name }}
                    </option>
                </select>
            </div>
            <div class="col-2">
                <button class="btn button-top" @click="download()">{{ $t("overviews.download") }}</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        warehouses: {
            type: Array,
            required: false,
            default: []
        },
    },
    data() {
        return {
            to: new Date(),
            from: null,
            selectedWarehouse: null
        }
    },
    created: function () {
        this.to = this.getDateFormatted(new Date());
        var from = new Date();
        from.setMonth(from.getMonth() - 1);
        this.from = this.getDateFormatted(from);
    },
    methods: {
        getDateFormatted: function (date) {
            return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
        },
        download: function () {
            this.$store.getters.api.get('/stats_customers', {
                params: { to: this.to, from: this.from, warehouse: this.selectedWarehouse },
                responseType: 'blob' }).then(function (response) {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                //create default name
                link.setAttribute('download', 'zakaznici.xlsx');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }.bind(this));
        }
    }
}
</script>
<style scoped lang="scss">
@media (min-width: 992px) {
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>