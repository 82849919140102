<template>
    <div class="mt-5">
        <h3>{{ $t("overviews.warehouseOverview") }}</h3>
        <div class="row align-items-center mb-2 mt-4">
            <div class="col-2">
                <button @click="setWarehouseLastWeek()" class="btn button-top">{{ $t("overviews.lastWeek") }}</button>
            </div>
            <div class="col-2">
                <button @click="setWarehouseLastMonth()" class="btn button-top">{{ $t("overviews.lastMonth") }}</button>
            </div>
            <div class="col-2">
                <button @click="setWarehouseLastYear()" class="btn button-top">{{ $t("overviews.lastYear") }}</button>
            </div>
            <div class="col-2">
                <button @click="setWarehouseActualMonth()" class="btn button-top">{{ $t("overviews.actualMonth")
                    }}</button>
            </div>
        </div>
        <div class="row mb-1 align-items-center">
            <div class="col-2">
                <label class="control-label">{{ $t("overviews.from") }}</label>
            </div>
            <div class="col-2">
                <label class="control-label">{{ $t("overviews.to") }}</label>
            </div>
            <div class="col-2">

            </div>
            <div class="col-4">
                <label class="control-label">{{ $t("overviews.finishedOrders") }}</label>
            </div>
        </div>
        <div class="row mb-3 align-items-center">
            <div class="col-2">
                <input type="date" v-model="fromWarehouses" :max="toWarehouses" class="form-control" />
            </div>
            <div class="col-2">
                <input type="date" v-model="toWarehouses" :min="fromWarehouses" class="form-control" />
            </div>
            <div class="col-2">
                <select v-model="selection" name="selection" id="selection" class="form-select">
                    <option :value="null">{{ $t("overviews.everything") }}</option>
                    <option value="transport">{{ $t("overviews.transport") }}</option>
                    <option value="products">{{ $t("overviews.products") }}</option>
                </select>
            </div>
            <div class="col-4">
                <input type="checkbox" v-model="warehouses_done" class="form-check-input">
            </div>
        </div>
        <div class="row">
            <div id="CZK_warehouses_chart_div" style="width: 100%; height: 450px;" class="col-12 mb-2"></div>
            <div id="EUR_warehouses_chart_div" style="width: 100%; height: 450px;" class="col-12 mb-2"></div>
            <div id="count_warehouses_chart_div" style="width: 100%; height: 450px;" class="col-12 mb-2"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        warehouses: {
            type: Array,
            required: false,
            default: []
        },
    },
    data() {
        return {
            toWarehouses: null,
            fromWarehouses: null,
            warehouses: [],
            warehouses_done: true,
            selection: null
        }
    },
    watch: {
        toWarehouses: function () {
            this.getWarehousesStats()
        },
        fromWarehouses: function () {
            this.getWarehousesStats()
        },
        warehouses_done: function () {
            this.getWarehousesStats()
        },
        selection: function () {
            this.getWarehousesStats()
        }
    },
    created: function () {
        this.toWarehouses = this.getDateFormatted(new Date());
        var from = new Date();
        from.setMonth(from.getMonth() - 1);
        this.fromWarehouses = this.getDateFormatted(from);
        this.getWarehousesStats();
    },
    methods: {
        getDateFormatted: function (date) {
            return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
        },
        getWarehousesStats: function () {
            this.$store.getters.api.get('/stats_by_warehouses', { params: { to: this.toWarehouses, from: this.fromWarehouses, done: this.warehouses_done } }).then(function (response) {
                this.warehouses = response.data.data;
                this.draw(this.drawWarehousesChartCZK);
                this.draw(this.drawWarehousesChartEUR);
                this.draw(this.drawWarehousesCountChart);
            }.bind(this));
        },
        draw: function (fn, language) {
            google.charts.load("current", { packages: ["corechart"], 'language': "cs" });
            google.charts.setOnLoadCallback(fn);
        },
        drawWarehousesChartCZK: function () {
            this.drawWarehousesChart("CZK");
        },
        drawWarehousesChartEUR: function () {
            this.drawWarehousesChart("EUR");
        },
        drawProductsChartEUR: function () {
            this.drawProductsChart("EUR");
        },
        drawProductsChartCZK: function () {
            this.drawProductsChart("CZK");
        },
        drawWarehousesChart: function (currency) {
            var array = [];
            var formatterColumns = [];

            if (this.selection === "transport") {
                array = [["Sklad", "Cena dopravy", { role: 'style' }]];
                for (var i = 0; i < this.warehouses.length; i++) {
                    array.push([
                        this.warehouses[i].name,
                        this.warehouses[i].prices[currency].travel,
                        this.shadeColor(this.warehouses[i].color, -30)
                    ]);
                }
                formatterColumns = [1]; // Only format transport price
            } else if (this.selection === "products") {
                array = [["Sklad", "Cena produktů", { role: 'style' }]];
                for (var i = 0; i < this.warehouses.length; i++) {
                    array.push([
                        this.warehouses[i].name,
                        this.warehouses[i].prices[currency].products,
                        this.shadeColor(this.warehouses[i].color, -30)
                    ]);
                }
                formatterColumns = [1]; // Only format product price
            } else {
                array = [["Sklad", "Celkem", { role: 'style' }]];

                for (var i = 0; i < this.warehouses.length; i++) {
                    array.push([
                        this.warehouses[i].name,
                        this.warehouses[i].prices[currency].total,
                        this.shadeColor(this.warehouses[i].color, -50)
                    ]);
                }
                formatterColumns = [1]; // Format all price columns
            }

            var data = google.visualization.arrayToDataTable(array);
            var suffix = (currency === "CZK") ? " Kč" : " Eur";

            var options = {
                title: `Hodnota zakázek pro dané sklady v ${currency}`,
                backgroundColor: { fill: "#FFFFFF", fillOpacity: 0.5 },
                legend: { position: "none" },
                titleTextStyle: { fontSize: 18 },
                isStacked: true,
                annotations: {
                    textStyle: { color: "black", bold: true },
                    alwaysOutside: true
                }
            };

            var formatter = new google.visualization.NumberFormat({ suffix: suffix });

            //  Only apply formatter to valid columns
            formatterColumns.forEach(index => formatter.format(data, index));

            var chart = new google.visualization.ColumnChart(
                document.getElementById(currency + "_warehouses_chart_div")
            );
            chart.draw(data, options);
        },
        drawWarehousesCountChart: function () {
            var array = [["Sklad", "Počet", { role: 'style' }]]
            for (var i = 0; i < this.warehouses.length; i++) {
                array.push([this.warehouses[i].name, this.warehouses[i].count, this.warehouses[i].color])
            }
            var data = google.visualization.arrayToDataTable(array);

            var options = {
                title: 'Počet zakázek pro dané sklady',
                backgroundColor: {
                    fill: '#FFFFFF',
                    fillOpacity: 0.5
                },
                legend: { position: "none" },
                titleTextStyle: {
                    fontSize: 18
                }
            };

            var chart = new google.visualization.ColumnChart(document.getElementById('count_warehouses_chart_div'));
            chart.draw(data, options);
        },
        drawProductsChart: function (currency) {
            var array = [["Product", "Hodnota"]]
            for (var i = 0; i < this.products.length; i++) {
                array.push([this.products[i].name, this.products[i].prices[currency]])
            }
            var data = google.visualization.arrayToDataTable(array);

            var suffix = ((currency == "CZK") ? ' Kč' : ' Eur');

            var options = {
                title: 'Hodnota objednávek daných produktů v ' + currency,
                backgroundColor: {
                    fill: '#FFFFFF',
                    fillOpacity: 0.5
                },
                legend: { position: "none" },
                titleTextStyle: {
                    fontSize: 18
                }
            };

            var formatter = new google.visualization.NumberFormat(
                { suffix: suffix });
            formatter.format(data, 1);

            var chart = new google.visualization.ColumnChart(document.getElementById(currency + '_production_chart_div'));
            chart.draw(data, options);
        },
        drawProductionCountChart: function () {
            var array = [["Product", "Počet", { type: 'string', role: 'tooltip', p: { 'html': true } }]]
            for (var i = 0; i < this.products.length; i++) {
                var tooltip = "<span style='font-size: 14px;'><b>" + this.products[i].name + "</b><br>";
                tooltip += "Počet: <b>" + this.products[i].count + " " + this.products[i].measurement_unit + "</b></span>";
                array.push([this.products[i].name, this.products[i].count, tooltip])
            }
            var data = google.visualization.arrayToDataTable(array);

            var options = {
                tooltip: { isHtml: true },
                title: 'Počet kusů dle produktů',
                backgroundColor: {
                    fill: '#FFFFFF',
                    fillOpacity: 0.5
                },
                legend: { position: "none" },
                titleTextStyle: {
                    fontSize: 18
                }
            };

            var chart = new google.visualization.ColumnChart(document.getElementById('count_production_chart_div'));
            chart.draw(data, options);
        },
        setWarehouseLastWeek: function () {
            var to = new Date()
            to.setDate(to.getDate() - to.getDay());
            this.toWarehouses = this.getDateFormatted(to);
            var from = new Date();
            from.setDate(from.getDate() - 6 - from.getDay());
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        setWarehouseLastMonth: function () {
            var to = new Date();
            to.setMonth(to.getMonth());
            to.setDate(0);
            var from = new Date();
            from.setMonth(from.getMonth() - 1);
            from.setDate(1);
            this.toWarehouses = this.getDateFormatted(to);
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        setWarehouseLastYear: function () {
            var to = new Date();
            to.setFullYear(to.getFullYear() - 1);
            to.setMonth(12);
            to.setDate(0);
            var from = new Date();
            from.setFullYear(from.getFullYear() - 1);
            from.setMonth(0);
            from.setDate(1);
            this.toWarehouses = this.getDateFormatted(to);
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        setWarehouseActualMonth: function () {
            var to = new Date();
            to.setMonth(to.getMonth() + 1);
            to.setDate(0);
            this.toWarehouses = this.getDateFormatted(to);
            var from = new Date();
            from.setDate(1);
            this.fromWarehouses = this.getDateFormatted(from);
            this.getWarehousesStats();
        },
        shadeColor: function (color, percent) {
            var R = parseInt(color.substring(1, 3), 16);
            var G = parseInt(color.substring(3, 5), 16);
            var B = parseInt(color.substring(5, 7), 16);

            R = parseInt(R * (100 + percent) / 100);
            G = parseInt(G * (100 + percent) / 100);
            B = parseInt(B * (100 + percent) / 100);

            R = (R < 255) ? R : 255;
            G = (G < 255) ? G : 255;
            B = (B < 255) ? B : 255;

            R = Math.round(R)
            G = Math.round(G)
            B = Math.round(B)

            var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
            var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
            var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

            return "#" + RR + GG + BB;
        }
    }
}
</script>
<style scoped lang="scss">
@media (min-width: 992px) {
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
</style>