<template>
    <div class="w-50 row">
        <div class="col-12 mt-4">
            <h3>{{ $t("products.productPrice") }}</h3>
        </div>
        <div class="col-12">
            <label for="warehouse" class="mt-2">{{ $t("products.warehouse") }}</label>
        </div>
        <div class="col-12">
            <select v-model="warehouse" name="warehouse" id="warehouse" class="form-control form-select" size="1">
                <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                    {{ warehouse.name }}, {{ warehouse.address.full_address }}
                </option>
            </select>
        </div>
        <div class="col-12">
            <label for="currency" class="mt-2">{{ $t("products.currency") }}</label>
        </div>
        <div class="col-12">
            <select v-model="currency" name="currency" id="currency" class="form-control form-select">
                <option value="CZK">CZK</option>
                <option value="EUR">EUR</option>
            </select>
        </div>
        <div class="col-12">
            <label for="price" class="mt-2">{{ $t("products.vatPrice") }}</label>
        </div>
        <div class="col-12">
            <input v-model="price" type="number" class="form-control" id="price" name="price" placeholder="100" min="0"
                max="1000000">
        </div>
        <div class="col-12">
            <label for="vat" class="mt-2">{{ $t("products.vat") }}</label>
        </div>
        <div class="col-12">
            <select v-model="vat" name="vat" id="vat" class="form-control form-select">
                <option value="0">0 %</option>
                <option value="0.1">10 %</option>
                <option value="0.13">13 %</option>
                <option value="0.15">15 %</option>
                <option value="0.20">20 %</option>
                <option value="0.21" selected="selected">21 %</option>
            </select>
        </div>
        <div class="col-12">
            <label for="from_date" class="mt-2">{{ $t("products.date") }}</label>
        </div>
        <div class="col-12">
            <input v-model="from_date" type="date" class="form-control" id="from_date"
                name="from_date">
        </div>
        <div class="col-12">
            <button @click="submitForm()" type="submit" class="mt-4 w-100 btn btn-primary form-submit">
                {{ $t("utils.edit") }}
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        warehouses: {
            type: Array,
            required: true
        },
        productId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            warehouse: null,
            currency: null,
            price: null,
            vat: null,
            from_date: null,
        };
    },
    methods: {
        submitForm() {
            const priceData = {
                product_id: this.productId,
                warehouse: this.warehouse,
                currency: this.currency,
                price: this.price,
                vat: this.vat,
                from_date: this.from_date,
            };
            this.$store.getters.api.put("/product/" + this.productId + "/change_price", priceData)
                .then((response) => {
                    this.priceData = response.data;
                    this.$router.push({ "name": "Products" });
                });
        }
    },
}
</script>
<style scoped lang="scss"></style>