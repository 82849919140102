<template>
    <div class="px-3">
        <div class="month-selector" v-if="!hideHeader">
            <div class="row">
                <span class="col-auto no-select">
                    <span class="btn button-today cursor-pointer" @click="selectToday" role="button">{{
                        $t("dashboard.today") }}</span>
                </span>
                <span @click="previousWeek" class="previous-month col-1 no-select d-none d-md-block">&#10094;</span>
                <span @click="nextWeek" class="next-month col-1 no-select d-none d-md-block">&#10095;</span>
                <span class="col-auto">{{ months[selectedDate.getMonth()] }} {{ selectedDate.getFullYear() }}</span>
                <span class="col d-none d-lg-block" style="flex-grow: 1; text-align: right;">
                    {{ selectedWeekNumber + '. týden (' + selectedWeekEven + ')' }}
                </span>
            </div>
        </div>
        <div class="calendar-container">
            <div class="supportive-column">
                <div class="day-heading"></div>
                <div>
                    <div v-for="i in (calendarTo - calendarFrom)" class="hour-day-row" :id="i + calendarFrom - 1">
                        {{ i + calendarFrom - 1 }}
                    </div>
                </div>
            </div>
            <div v-if="!showOneDay">
                <div v-for="day in selectedWeek" :key="day.date" class="day-column d-none d-md-block">
                    <div class="day-heading"
                        :class="{ 'week-dark-col': dayShortName[day.date.getDay()] === 'So' || dayShortName[day.date.getDay()] === 'Ne' }">
                        <div class="weekday">{{ dayShortName[day.date.getDay()] }}</div>
                        <div class="date">{{ day.date.getDate() }}</div>
                    </div>
                    <div :id="'day' + day.date.getDate()" class="hours-column"
                        :class="{ 'week-dark-col': dayShortName[day.date.getDay()] === 'So' || dayShortName[day.date.getDay()] === 'Ne' }">
                        <div v-for="i in (calendarTo - calendarFrom) * 4" class="quarter-hour-day-row">
                        </div>
                        <div class="actual-time-line" v-if="isTodayFullDate(day.date) && isInSelectedTime()"
                            :style="{ top: positionTime }">
                        </div>
                        <div class="actual-time-point" v-if="isTodayFullDate(day.date) && isInSelectedTime()"
                            :style="{ top: positionTime }">
                        </div>
                        <div v-for="order in day.orders" :key="order.id">
                            <div v-if="showTravelTime" :key="order.id"
                                :style="{ top: positionBeforeOrderTravel(order), height: maxHeight(deliveryHeight(order), positionBeforeOrderTravel(order)), backgroundColor: transparentHexColor(order.warehouse.color) }"
                                class="calendar-order" @click="selectOrder(order)">
                            </div>
                            <div v-if="maxHeight(heightOrder(order), positionOrder(order)) != '0%'">
                                <div :key="order.id"
                                    :style="{ top: positionOrder(order), height: maxHeight(heightOrder(order), positionOrder(order)), backgroundColor: order.warehouse.color, color: order.warehouse.text_color }"
                                    class="calendar-order calendar-order-main" @click="selectOrder(order)">
                                    <div class="d-flex justify-content-center aling-items-center p-2">
                                        <span class="order-number" v-if="showIds">{{ order.intern_id }}</span>
                                        <span class="order-number" v-else>{{ order.calendar_name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showTravelTime" :key="order.id"
                                :style="{ top: positionAfterOrderTravel(order), height: maxHeight(deliveryHeight(order), positionAfterOrderTravel(order)), backgroundColor: transparentHexColor(order.warehouse.color) }"
                                class="calendar-order" @click="selectOrder(order)">
                            </div>
                        </div>
                        <div v-for="vacation in day.vacations" :key="vacation.id">
                            <div v-if="maxHeight(heightVacation(vacation), positionVacation(vacation)) != '0%'">
                                <div :key="vacation.id"
                                    :style="{ top: positionVacation(vacation), height: maxHeight(heightVacation(vacation), positionVacation(vacation)), backgroundColor: vacation.warehouse.color, color: vacation.warehouse.text_color }"
                                    class="calendar-order calendar-order-main">
                                    <div class="d-flex justify-content-center aling-items-center p-2"
                                        :style="{ top: textPosition(heightVacation(vacation), positionVacation(vacation)), position: 'relative' }">
                                        <span class="order-number">{{ $t("vacations.vacation") }} - {{ vacation.warehouse.name
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="day-column one-day-column d-block d-md-none">
                    <CalendarOneDayColumn
                        :orders="selectedDateOrders"
                        :vacations="selectedDateVacations"
                        :selectedOrderOutside="selectedOrderOutside"
                        :selectedDate="selectedDate"
                        :calendarFrom="calendarFrom"
                        :calendarTo="calendarTo"
                        :showTravelTime="showTravelTime"
                        :showIds="showIds"
                        @select-order="selectOrder"/>
                </div>
            </div>

            <div class="day-column one-day-column" v-else>
                <CalendarOneDayColumn
                        ref="oneday"
                        :orders="selectedDateOrders"
                        :vacations="selectedDateVacations"
                        :selectedOrderOutside="selectedOrderOutside"
                        :selectedDate="selectedDate"
                        :calendarFrom="calendarFrom"
                        :calendarTo="calendarTo"
                        :showTravelTime="showTravelTime"
                        :showIds="showIds"
                        @select-order="selectOrder"/>
            </div>
        </div>
    </div>
</template>
<script>
import CalendarOneDayColumn from "@/components/CalendarOneDayColumn.vue";

export default {
    components: {
        CalendarOneDayColumn,
    },
    props: {
        showOneDay: Boolean,
        showIds: Boolean,
        calendarFrom: {
            type: Number,
            default: 6,
        },
        calendarTo: {
            type: Number,
            default: 18,
        },
        selectedWarehouses: {
            type: Array, // Adjust the type based on your data
            required: true,
        },
        showTravelTime: {
            type: Boolean,
            required: true,
        },
        selectedDate: {
            type: Date,
            required: true,
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        selectedOrderOutside: {
            type: Object,
            default: {},
            required: false
        },
        showCobined: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            warehouses: [],
            today: new Date(),
            orderTimeout: null,
            selectedWeek: [],
            currentYear: null,
            currentMonth: null,
            selectedDateOrders: [],
            selectedDateVacations: [],
            updateTimeInterval: null,
            selectedOrder: null,
            months: [
                "Leden",
                "Únor",
                "Březen",
                "Duben",
                "Květen",
                "Červen",
                "Červenec",
                "Srpen",
                "Září",
                "Říjen",
                "Listopad",
                "Prosinec"
            ],
            dayShortName: [
                "Ne",
                "Po",
                "Út",
                "St",
                "Čt",
                "Pá",
                "So"
            ]
        }
    },
    created: function () {
        this.currentMonth = this.today.getMonth();
        this.currentYear = this.today.getFullYear();
        this.updateSelectedWeekBySelectedDate();

        // interval to update time
        this.updateTimeInterval = setInterval(function () {
            this.today = new Date();
        }.bind(this), 60000);

        setTimeout(function () {
            this.getOrders();
        }.bind(this), 1000);

    },
    watch: {
        selectedWarehouses: {
            handler() {
                this.getOrders();
            },
            deep: true
        },
        selectedDate: function () {
            this.updateSelectedWeekBySelectedDate();
        }
    },
    computed: {
        daysInMonth: function () {
            return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        },
        selectedWeekNumber: function () {
            var startDate = new Date(this.selectedDate.getFullYear(), 0, 1);
            var days = Math.floor((this.selectedDate - startDate) /
                (24 * 60 * 60 * 1000)) + 1;

            return Math.ceil(days / 7) + 1;
        },
        selectedWeekEven: function () {
            return this.selectedWeekNumber % 2 ? "lichý" : "sudý";
        },
        daysInPreviosMonth: function () {
            var actualStart = new Date(this.currentYear, this.currentMonth, 1).getDay();
            // remove number of days
            if (actualStart == 0) {
                actualStart = 6
            }
            else {
                actualStart -= 1
            }
            var previousMonth = this.currentMonth - 1;
            var previousYear = this.currentYear;
            if (previousMonth <= -1) {
                previousMonth = 11;
                previousYear -= 1;
            }

            // create date list
            dates = []
            maxDates = new Date(previousYear, previousMonth + 1, 0).getDate();
            for (var i = maxDates - actualStart + 1; i <= maxDates; i++) {
                dates.push(i)
            }

            return dates
        },
        daysInNextMonth: function () {
            var actualEnd = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
            // remove number of days
            actualEnd = new Date(this.currentYear, this.currentMonth, actualEnd).getDay();
            if (actualEnd == 0) {
                actualEnd = 7;
            }
            // create date list
            dates = []
            for (var i = 1; i <= 7 - actualEnd; i++) {
                dates.push(i)
            }

            return dates
        },
        positionTime: function () {
            return this.positionItem(this.today);
        },
        selectedDateCalendar: function () {
            for (var i = 0; i < this.selectedWeek.length; i++) {
                if (this.sameDay(this.selectedWeek[i].date, this.selectedDate)) {
                    return this.selectedWeek[i];
                }
            }
        }
    },
    methods: {
        getOrders: function () {
            if (this.orderTimeout) {
                clearTimeout(this.orderTimeout);
            }
            this.orderTimeout = setTimeout(function () {
                var that = this;
                var dates = []
                for (var i = 0; i < this.selectedWeek.length; i++) {
                    var tmp = this.selectedWeek[i].date;
                    var date = tmp.getFullYear() + "-" + (tmp.getMonth() + 1).toString().lpad("0", 2) + "-" + tmp.getDate().toString().lpad("0", 2);
                    dates.push(date)
                }
                var params = {
                        warehouses: this.selectedWarehouses,
                        dates: dates,
                        show_cobined: this.showCobined
                }
                if (this.selectedOrderOutside) {
                    params["id_out"] = this.selectedOrderOutside.id
                }
                this.$store.getters.api.get('/api/get-orders', {
                    params: params
                }).then(function (response) {
                    var data = response.data.data;
                    for (var x = 0; x < that.selectedWeek.length; x++) {
                        that.selectedWeek[x].orders = [];
                        for (var i = 0; i < data.length; i++) {
                            var order_date = new Date(data[i].expected_delivery_date);
                            if (order_date.toDateString() === that.selectedWeek[x].date.toDateString()) {
                                that.selectedWeek[x].orders.push(data[i]);
                            }
                        }
                        if (that.selectedWeek[x].date.toDateString() == that.selectedDate.toDateString()) {
                            that.selectedDateOrders = that.selectedWeek[x].orders;
                        }
                    }
                });
                this.$store.getters.api.get('/api/get-vacations', {
                    params: {
                        warehouses: this.selectedWarehouses,
                        dates: dates
                    }
                }).then(function (response) {
                    var data = response.data.data;
                    for (var x = 0; x < that.selectedWeek.length; x++) {
                        that.selectedWeek[x].vacations = [];
                        for (var i = 0; i < data.length; i++) {
                            var order_date = new Date(data[i].date);
                            if (order_date.toDateString() === that.selectedWeek[x].date.toDateString()) {
                                that.selectedWeek[x].vacations.push(data[i]);
                            }
                        }

                        if (that.selectedWeek[x].date.toDateString() == that.selectedDate.toDateString()) {
                            that.selectedDateVacations = that.selectedWeek[x].vacations;
                        }
                    }
                });
            }.bind(this), 500);
        },
        previousMonth: function () {
            this.currentMonth -= 1;
            if (this.currentMonth <= -1) {
                this.currentMonth = 11;
                this.currentYear -= 1;
            }
        },
        nextMonth: function () {
            this.currentMonth += 1;
            if (this.currentMonth >= 12) {
                this.currentMonth = 0;
                this.currentYear += 1;
            }
        },
        previousWeek: function () {
            var date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate() - 7);
            this.$emit('update:selectedDate', null);
            this.$emit('update:selectedDate', date);
            this.updateCurrentMonthYear();
            this.updateSelectedWeekBySelectedDate();
        },
        nextWeek: function () {
            var date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate() + 7);
            this.$emit('update:selectedDate', null);
            this.$emit('update:selectedDate', date);
            this.updateCurrentMonthYear();
            this.updateSelectedWeekBySelectedDate();
        },
        updateCurrentMonthYear: function () {
            this.currentMonth = this.selectedDate.getMonth();
            this.currentYear = this.selectedDate.getFullYear();
        },
        isSelected: function (i) {
            return i == this.selectedDate.getDate()
                && this.selectedDate.getMonth() == this.currentMonth
                && this.selectedDate.getFullYear() == this.currentYear;
        },
        isToday: function (i) {
            this.today = new Date();
            return !this.isSelected(i) && i == this.today.getDate()
                && this.today.getMonth() == this.currentMonth
                && this.today.getFullYear() == this.currentYear;
        },
        selectToday: function () {
            this.$emit('update:selectedDate', new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()));
            this.updateCurrentMonthYear();
            this.updateSelectedWeekBySelectedDate();
        },
        selectDate: function (i) {
            this.$emit('update:selectedDate', new Date(this.currentYear, this.currentMonth, i));
            this.updateCurrentMonthYear();
            this.updateSelectedWeekBySelectedDate();
        },
        updateSelectedWeekBySelectedDate: function () {
            var dates = []

            var date = this.selectedDate;
            var weekDay = date.getUTCDay();
            for (var i = weekDay; i >= 0; i--) {
                dates.push(new Date(date.getFullYear(), date.getMonth(), date.getDate() - i))
            }
            for (var i = 1; i < (7 - weekDay); i++) {
                dates.push(new Date(date.getFullYear(), date.getMonth(), date.getDate() + i))
            }

            this.selectedWeek = []
            for (var i = 0; i < dates.length; i++) {
                this.selectedWeek.push({ date: dates[i], orders: [] })
            }
            this.getOrders();
        },
        isTodayFullDate: function (date) {
            return this.sameDay(this.today, date);
        },
        isInSelectedTime: function () {
            var hours = this.today.getHours();
            if (hours >= this.calendarFrom && hours < this.calendarTo) {
                return true;
            }
            return false;
        },
        positionInCalendar: function (minutesPassed) {
            var totalDayMinutes = (this.calendarTo - this.calendarFrom) * 60;
            return ((minutesPassed / totalDayMinutes) * 100) + "%"
        },
        positionItem: function (datetime) {
            var totalMinutesPassed = (datetime.getHours() - this.calendarFrom) * 60 + datetime.getMinutes();
            return this.positionInCalendar(totalMinutesPassed);
        },
        positionOrder: function (order) {
            if (order.expected_delivery_date && order.expected_delivery_time) {
                var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time)
                return this.positionItem(date);
            }
        },
        positionVacation: function (vacation) {
            if (vacation.date && vacation.from_time) {
                var date = new Date(vacation.date + " " + vacation.from_time);
                return this.positionItem(date);
            }
        },
        positionBeforeOrderTravel: function (order) {
            if (order.expected_delivery_date && order.expected_delivery_time) {
                var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time)
                date = this.addMinutes(date, (-1) * order.duration);
                return this.positionItem(date);
            }
        },
        positionAfterOrderTravel: function (order) {
            if (order.expected_delivery_date && order.expected_delivery_time) {
                var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time)
                date = this.addMinutes(date, order.expected_delivery_duration);
                return this.positionItem(date);
            }
        },
        heightOrder: function (order) {
            return this.positionInCalendar(order.expected_delivery_duration);
        },
        heightVacation: function (vacation) {
            return this.positionInCalendar(vacation.duration);
        },
        deliveryHeight: function (order) {
            return this.positionInCalendar(order.duration);
        },
        orderRedirect: function (order) {
            window.location.replace("/order/" + order.id);
        },
        sameDay: function (date1, date2) {
            return date1.getFullYear() == date2.getFullYear()
                && date1.getMonth() == date2.getMonth()
                && date1.getDate() == date2.getDate();
        },
        addMinutes: function (date, minutes) {
            return new Date(date.getTime() + minutes * 60000);
        },
        transparentHexColor: function (color) {
            // transparent hex color
            return color + 'cc';
        },
        selectOrder: function (order) {
            this.selectedOrder = order;
            this.$emit('update:selectedOrder', this.selectedOrder);
        },
        deselectOrder: function () {
            this.selectedOrder = null;
        },
        orderDateFormatter: function (order) {
            var date = new Date(order.expected_delivery_date + " " + order.expected_delivery_time);
            return date.toLocaleString();
        },
        maxHeight: function (height, pos) {
            var n_hieght = parseFloat(height.replace("%", ""));
            var n_pos = parseFloat(pos.replace("%", ""));
            if (n_pos + n_hieght >= 100) {
                var possible_height = 100 - n_pos;
                if (possible_height <= 0) {
                    return "0%";
                }

                return possible_height + "%";
            }
            return n_hieght + "%";
        },
        textPosition: function (height, pos) {
            var n_hieght = parseFloat(height.replace("%", ""));
            var n_pos = parseFloat(pos.replace("%", ""));
            if (n_pos + n_hieght >= 100) {

                return "calc(" + Math.abs(n_pos * 1.5) + "% - 70px)";
            }
            return "calc(50% - 70px)";
        },
        overlapingOrders: function () {
            return this.$refs.oneday.overlapingOrders();
        }
    }
}


</script>
<style lang="">

</style>