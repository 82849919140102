<template>
    <div class="row py-5">
        <div class="col-12 px-5">
            <div class="row">
                <div class="col-12">
                    <h2>{{ $t("customer.customerDetail") }} - {{ customer.intern_id }}</h2>
                </div>
                <div>
                    <button v-if="hasPerm()" class="btn form-submit d-flex align-items-center justify-content-center"
                        style="height: 30px;" @click="changeToContact()">
                        <span style="font-size: 17px;">{{ customer.to_contact ? $t("customer.dontContact") :
                            $t("customer.toContact") }}</span>
                    </button>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <span>{{ $t("customer.firstName") }}: <b>{{ customer.first_name }} {{ customer.last_name
                            }}</b></span><br>
                    <span>{{ $t("customer.organizationId") }}: <b>{{ customer.organization_id }}</b></span><br>
                    <span>{{ $t("customer.organizationName") }}: <b>{{ customer.organization_name }}</b></span><br>
                    <span>{{ $t("customer.phone") }}: <b><a href="tel:{{ customer.whole_phone }}"
                                class="phone-number link-darker">{{
                                    customer.whole_phone }}</a></b></span><br>
                    <span>{{ $t("customer.email") }}: <b>
                            <a href="mailto:{{ customer.email }}" class="link-darker">{{ customer.email }}</a>
                        </b></span><br>
                    <span>{{ $t("customer.address") }}:
                        <b>
                            <a href="{{ customer.address ? customer.address.full_address : '#' }}" class="link-darker"
                                target="_blank">
                                {{ customer.address ? customer.address.full_address : '-' }}
                            </a>
                        </b>
                    </span> <br>
                    <span><b><a href="{{ customer.address.create_waze_link }}" class="link-darker" target="_blank">
                                {{ $t("customer.openWaze") }}
                            </a></b></span><br>
                    <div class="d-flex align-items-center">
                        <div class="me-2">
                            <span>{{ $t("customer.toContact") }}:</span>
                            <span v-if="customer.to_contact" class="ms-1">{{ $t("orders.yes") }}</span>
                            <span v-if="!customer.to_contact" class="ms-1">{{ $t("orders.no") }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <div id="customer-map" style="height:300px; max-width: 100%;"></div>
                    <div ref="map"></div>
                </div>
                <div v-if="$store.getters.hasPerm('view_order')" class="">
                    <div class="col-12 mt-4">
                        <h3>{{ $t("customer.orderList") }}</h3>
                    </div>
                    <div class="col-12">
                        <div class="w-100 row py-4 item-line">
                            <div class="col-6 col-md-1">{{ $t("customer.id") }}</div>
                            <div class="col-6 col-md-2">{{ $t("customer.dateOfCreation") }}</div>
                            <div class="col-6 col-md-2">{{ $t("customer.dateOfDelivery") }}</div>
                            <div class="col-12 col-md-4">{{ $t("customer.address") }}</div>
                        </div>
                        <div v-for="order in orders" :key="order.id" class="w-100 row py-4 item-line">
                            <div class="col-6 col-md-1">{{ order.intern_id }}</div>
                            <div class="col-6 col-md-2">{{ order.created }}</div>
                            <div class="col-6 col-md-2">
                                <span>{{ order.expected_delivery_date }}</span>
                            </div>
                            <div class="col-11 col-md-3">
                                {{ customer.address ? customer.address.full_address : 'N/A' }}
                            </div>
                            <div class="col-1">
                                <a href="/order/{{ order.id }}/copy" class="button">{{ $t("utils.copy") }}</a>
                            </div>
                            <div class="col-1">
                                <router-link :to="{ name: 'OrderDetail', params: { id: order.id } }">
                                    {{ $t("utils.detail") }}
                                </router-link>
                            </div>
                            <div class="col-1">
                                <router-link :to="{ name: 'UpdateOrderItems', params: { id: order.id } }">
                                    {{ $t("utils.edit") }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            customerId: null,
            customer: {},
            orders: [],
            map: null,
        };
    },
    mounted() {
        this.loadMap();
    },
    created: function () {
        this.customerId = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api
                .get("/customer/" + this.customerId)
                .then((response) => {
                    this.customer = response.data.customer;
                    this.orders = response.data.orders;
                    this.initializeMap();
                })
                .catch((response) => {
                    alert(this.$t("error." + response?.response?.data?.error_code));
                    var status = response?.response?.status;
                    if (status == 404) {
                        this.$router.push({ name: "Customers" });
                    }
                });
        },
        loadMap() {
            // Load Seznam Maps library
            window.Loader.async = true;
            window.Loader.load(null, null, null, null, "cs_CZ");
            window.Loader.load(null, null, null, null, "cs_CZ");

            window.Loader.onload = () => {
                this.initializeMap();
            };
        },
        initializeMap() {
            // Check if customer has an address
            if (this.customer.address && this.customer.address.gps_lon && this.customer.address.gps_lat) {
                const lon = this.customer.address.gps_lon;
                const lat = this.customer.address.gps_lat;

                const center = SMap.Coords.fromWGS84(lon, lat);
                this.map = new SMap(document.getElementById("customer-map"), center, 15);
                this.map.addDefaultLayer(SMap.DEF_BASE).enable();
                this.map.addDefaultControls();

                const layer = new SMap.Layer.Marker();
                this.map.addLayer(layer);
                layer.enable();

                const options = {};
                const marker = new SMap.Marker(center, "myMarker", options);
                layer.addMarker(marker);
            }
        },
        changeToContact() {
            this.$store.getters.api.put("/customer/" + this.customerId + "/change_to_contact").then((response) => {
                this.loadData();
            });
        },
        hasPerm: function () {
            var perm = false;
            this.$store.state.user.functions.forEach(element => {
                if (element.name == "owner" || element.name == "dispatcher") {
                    perm = true;
                }
            })
            return perm;
        },
    },
};
</script>
<style lang="scss"></style>